import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import prod1 from "../../images/cool.png";
import prod2 from "../../images/substance.png";
import prod4 from "../../images/dry.png";
import { Container } from "react-bootstrap";
import "./ExploreProducts.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import ExoploreProductBanner from "../ExploreProductBanner/ExoploreProductBanner";
import ProcessedProducts from "./ProcessedProducts";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import { NavHashLink } from "react-router-hash-link";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

const ExploreProducts = () => {
  const location = useLocation();
  const history = useHistory();
  const customPropValue = location.state?.customProp;

  let navData = [
    { to: "/onMarketplace", link: "For B2C Buyers",  },
      { to: "/onMarketplaceSell", link: "For Sellers" }
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

  // Conditionally include customPropValue in navData
  if (customPropValue) {
    navData = [
      { to: "/onMarketplace", link: "For B2C Buyers",  },
      { to: "/b2b-onMarketplace", link: "For B2B Buyers",  },

      { to: "/onMarketplaceSell", link: "For Sellers",customPropValue }
      // { to: "/onMarketplaceRFQ", link: "RFQ" }
    ];
  }
  

  const [rawProduct, setRawProduct] = useState(true);
  const [semiProduct, setSemiProduct] = useState(false);
  const [processedProduct, setProcessedProduct] = useState(false);
  const [userType, setUserType] = useState(["FOOD-PROCESSOR"])

  // useEffect(() => {
  //   if (location.hash === "#Raw-Products") {
  //     setRawProduct(true);
  //     setSemiProduct(false);
  //     setProcessedProduct(false);
  //   } else if (location.hash === "#Semi-Processed-Products") {
  //     setRawProduct(false);
  //     setSemiProduct(true);
  //     setProcessedProduct(false);
  //   } else if (location.hash === "#Processed-Products") {
  //     setRawProduct(false);
  //     setSemiProduct(false);
  //     setProcessedProduct(true);
  //   }
  // }, [location.hash]);

  const rawProductHandler = () => {
    setRawProduct(true);
    setSemiProduct(false);
    setProcessedProduct(false);
    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#Raw-Products",
    //   state: { customProp: customPropValue },
    // });
  };

  const semiProductHandler = () => {
    setSemiProduct(true);
    setRawProduct(false);
    setProcessedProduct(false);
    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#Semi-Processed-Products",
    //   state: { customProp: customPropValue },
    // });
  };

  const processedProductHandler = () => {
    setProcessedProduct(true);
    setRawProduct(false);
    setSemiProduct(false);
    // history.push({
    //   pathname: "/onMarketplace",
    //   hash: "#Processed-Products",
    //   state: { customProp: customPropValue },
    // });
  };

  return (
    <>
      <HeaderComponent className="noShadow" />
      <SecondaryNavbar props={navData}/>
      {/* <div>
        <div className="serviceContainer ExploreBannerContainer" id="ExploreBannerContainer">
          <div className="gridSection">
            <h1 className="container-title mb-2 text-white">
              Ensured Quality | Traceability  | Provenance
            </h1>
            <p className="container-para text-white">
              Ensure food saftey across globe
            </p>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              direction="row"
            >
            <Grid id="complementaryServices_link" className="hoverEffect" item md={4} sm={4} xs={6}>
                <NavHashLink
                  onClick={rawProductHandler}
                  to={{
                    pathname: "/onMarketplace",
                    hash: "#Raw-Products",
                    state: { customProp: customPropValue }
                  }}
                >
                  <img src={prod2} alt="" />
                  <h4>
                    Raw <br className="d-none d-md-block"></br>Products
                  </h4>
                </NavHashLink>
              </Grid>

              <Grid id="complementaryServices_link" className="hoverEffect" item md={4} sm={4} xs={6}>
                <NavHashLink
                  onClick={semiProductHandler}
                  to={{
                    pathname: "/onMarketplace",
                    hash: "#Semi-Processed-Products",
                    state: { customProp: customPropValue }
                  }}
                >
                  <img src={prod4} alt="" />
                  <h4>
                    Semi Processed <br className="d-none d-md-block"></br>Products
                  </h4>
                </NavHashLink>
              </Grid>
             {!customPropValue && <Grid
                id="coreServices_link"
                className="hoverEffect"
                item
                md={4}
                sm={4}
                xs={6}
              >
                <a onClick={processedProductHandler} href="#Processed-Products">
                  <img src={prod1} alt="" />
                  <h4>
                    Processed <br className="d-none d-md-block"></br>Products
                  </h4>
                </a>
              </Grid>}
            </Grid>
          </div>
        </div>
      </div> */}


      <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Ensured Quality, Traceability  & Provenance of Processed products"
         bannerbodycopy= "Ensure food saftey across globe"
         
        />
      <div className="explore-product-wrapper">
        <Container>
          <div className=" ">
       
           <div id="tendersContainer" className="container">
              <h3 id="Processed-Products" className="container-title  ">
                Processed Products
              </h3>
              <ProcessedProducts quote="true" type="PROCESSED-PRODUCTS" userType = {userType}/>
            </div>
          </div>
        </Container>
      </div>
      <FooterComponent />
    </>
  );
};

export default ExploreProducts;
