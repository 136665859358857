import React from "react";
import NetworkBanner from "../WebSite/NetworkBanner/NetworkBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import CommunitySection from "../WebSite/CommunitySection/CommunitySection";
import ABNServices from "../OurServices/ABNServices";
import AgribusinessMainSection from "../AgribusinessMain/AgribusinessMainSection"
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import ABN from "../../images/Customized CMN Network infographic - CMN Network - 07-08-2024 C.gif";
import ABNBenefits from "../WebSite/Benefits/ABNBenefits";
import ABNNetworkOperator from "../ABNNetworkOperator/ABNNetworkOperator";
import { Col, Container, Row } from "react-bootstrap";
import AgribussinessAdditional from "../AgribusinessMain/AgribussinessAdditional";
import NetworkOperatorBenefits from "../ForNetworKOperators/NetworkOperatorBenefits";
import ABNNetworkBenefits from "./ABNNetworkBenefits";
import ABNMainServices from "./ABNMainServices"
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
export default function ABNComponent() {
  return (
    <>
      <HeaderComponent />
      {/* <UpdatesBanner  bannerText={["The Contract Manufacturing Network (CMN) is the world's first for the food manufacturing industry, ", <br clasName = "d-block d-md-none"></br>,  "hybrid network designed", <br clasName = "d-block d-md-none"></br>, "fostering Digital Trust within the food processing enterprise  ",<br clasName = "d-block d-md-none"></br>,    "ecosystem and equipping them with edge computing infrastructure"]} className="abnbg" /> */}
      <NetworkBanner bannerTitle="The Contract Manufacturing Network (CMN) is the world's first hybrid network designed for the food manufacturing industry, fostering Digital Trust within the food processing enterprise ecosystem and equipping them with edge computing infrastructure" className="abnbg" />
     
      <div className="mt-5 mb-5 ">
      <h4 className="container-title text-center mx-md-4 ">
      Managed CMN Network          </h4> 
      <Container>
        <Row className="justify-content-center">
          <Col md={6} sm={12}>
          <img
              className=" w-100 h-100 cursor-initial"
              src={ABN}
              alt="Infographics"
            />
          </Col>
        </Row>
      </Container>
      
     
      </div>
      <ABNMainServices/>
      <div className="">

      <ABNServices/>
</div>

<div className='main-wrapper2 pb-0 '>
           <Container className="subSectionWrapper">
           <ABNNetworkBenefits/>
        </Container>
        </div>

      <div className="">
        <div className="subSectionWrapper">
          <h4 className="container-title text-center mx-md-4 ">
          Network Beneficiaries & Their Services

          </h4>
          <AgribussinessAdditional/>
        </div>
      </div>


      <div id="ExploreCommunity" className="main-wrapper2">
        <CommunitySection exploreBtn="true" />
      </div>
     

      {/* <ABNNetworkOperator/> */}
      <FooterComponent />
    </>
  );
}
